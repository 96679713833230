//tools
@import "_misc/mixins";
@import "_misc/normalize";

*{
  @include box-sizing(border-box);
}

//@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;500;700;900&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;500;900&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;900&display=swap');

//@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;900&display=swap');

//@font-face {
//  font-family: 'Montserrat';
//  src: url('fonts/Montserrat-VariableFont_wght.ttf') format('woff2 supports variations'),
//  url('fonts/Montserrat-VariableFont_wght.ttf') format('woff2-variations');
//  font-weight: 100 900;
//}


/* montserrat-100 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-100.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-100.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-100.woff') format('woff'), /* Modern Browsers */
  url('../fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-100.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-100.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-300 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-300.woff') format('woff'), /* Modern Browsers */
  url('../fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-300.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-500 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-500.woff') format('woff'), /* Modern Browsers */
  url('../fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-regular - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-900 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-900.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-900.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-900.woff') format('woff'), /* Modern Browsers */
  url('../fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-900.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-900.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* oswald-200 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/oswald-v49-latin-ext_latin_cyrillic-ext_cyrillic-200.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/oswald-v49-latin-ext_latin_cyrillic-ext_cyrillic-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/oswald-v49-latin-ext_latin_cyrillic-ext_cyrillic-200.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/oswald-v49-latin-ext_latin_cyrillic-ext_cyrillic-200.woff') format('woff'), /* Modern Browsers */
  url('../fonts/oswald-v49-latin-ext_latin_cyrillic-ext_cyrillic-200.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/oswald-v49-latin-ext_latin_cyrillic-ext_cyrillic-200.svg#Oswald') format('svg'); /* Legacy iOS */
}
/* oswald-300 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/oswald-v49-latin-ext_latin_cyrillic-ext_cyrillic-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/oswald-v49-latin-ext_latin_cyrillic-ext_cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/oswald-v49-latin-ext_latin_cyrillic-ext_cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/oswald-v49-latin-ext_latin_cyrillic-ext_cyrillic-300.woff') format('woff'), /* Modern Browsers */
  url('../fonts/oswald-v49-latin-ext_latin_cyrillic-ext_cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/oswald-v49-latin-ext_latin_cyrillic-ext_cyrillic-300.svg#Oswald') format('svg'); /* Legacy iOS */
}
/* oswald-regular - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/oswald-v49-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/oswald-v49-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/oswald-v49-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/oswald-v49-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/oswald-v49-latin-ext_latin_cyrillic-ext_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/oswald-v49-latin-ext_latin_cyrillic-ext_cyrillic-regular.svg#Oswald') format('svg'); /* Legacy iOS */
}
/* oswald-500 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/oswald-v49-latin-ext_latin_cyrillic-ext_cyrillic-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/oswald-v49-latin-ext_latin_cyrillic-ext_cyrillic-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/oswald-v49-latin-ext_latin_cyrillic-ext_cyrillic-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/oswald-v49-latin-ext_latin_cyrillic-ext_cyrillic-500.woff') format('woff'), /* Modern Browsers */
  url('../fonts/oswald-v49-latin-ext_latin_cyrillic-ext_cyrillic-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/oswald-v49-latin-ext_latin_cyrillic-ext_cyrillic-500.svg#Oswald') format('svg'); /* Legacy iOS */
}
/* oswald-700 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/oswald-v49-latin-ext_latin_cyrillic-ext_cyrillic-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/oswald-v49-latin-ext_latin_cyrillic-ext_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/oswald-v49-latin-ext_latin_cyrillic-ext_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/oswald-v49-latin-ext_latin_cyrillic-ext_cyrillic-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/oswald-v49-latin-ext_latin_cyrillic-ext_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/oswald-v49-latin-ext_latin_cyrillic-ext_cyrillic-700.svg#Oswald') format('svg'); /* Legacy iOS */
}
/* oswald-600 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/oswald-v49-latin-ext_latin_cyrillic-ext_cyrillic-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/oswald-v49-latin-ext_latin_cyrillic-ext_cyrillic-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/oswald-v49-latin-ext_latin_cyrillic-ext_cyrillic-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/oswald-v49-latin-ext_latin_cyrillic-ext_cyrillic-600.woff') format('woff'), /* Modern Browsers */
  url('../fonts/oswald-v49-latin-ext_latin_cyrillic-ext_cyrillic-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/oswald-v49-latin-ext_latin_cyrillic-ext_cyrillic-600.svg#Oswald') format('svg'); /* Legacy iOS */
}

html{
  position: relative;

}

html, body{
  min-height: 100%;
  width: 100%;
}

body {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
}

h1,h2,h3,h4,h5,h6 {font-family:Oswald, sans-serif;}

.header {

  height: 150px;
  background: url("../img/design2022/sip_nimbers.png");

}

.container__desktop {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  @include phones{
    display: none;
  }
}

.container__mob{
  //display: flex;
  flex-direction: column;
  align-items: center;
  display: none;

  @include phones{
    display: flex;
    padding-top: 15px;

  }

}

.container__mob-down{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.container__mob-logo{
  width: 170px;
  & img{
    width: 100%;
    height: auto;
  }
}

.container__mob-phones{
  display: flex;
  flex-direction: column;
  margin-left: 2%;

  & a{
    text-decoration: none;
    font-family: Montserrat, sans-serif;
    font-size: 13px;
    font-weight: 700;
    line-height: 21px;
    color: #FFFFFF;

  }
}

.container__mob-btn{

  margin-right: 5%;

  & span {
    color: white;
    font-size: 70px;
  }
}

.container__mob-menu{
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #07133A;
  top: 0;
  display: none;
  z-index: 1;
}

.mob__nav{
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.mob__nav-header{
  display: flex;
  width: 100%;
  flex-direction: column;
}

.nav__header-up{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.nav__header-down{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

}

.mob__nav-logo{

  width: 145px;
  margin: 20px 10px;

  & img{
    width: 100%;
    height: auto;
  }
}

.mob__nav-phones{
  display: flex;
  flex-direction: column;
  margin-left: 4%;

  & a {
    text-decoration: none;
    font-family: Montserrat, sans-serif;
    font-size: 13px;
    font-weight: 700;
    line-height: 31px;
    color: #FFFFFF;
  }
}

.mob__nav-close{
  margin-right: 5%;
}

.languages__mob{

  list-style: none;
  //margin: 8px 11px;
  //padding: 0;
  position: absolute;
  background-color: #07133a;
  z-index: 1;
  padding: 25px;
  top: 15px;
  right: -20px;

  & a{
    text-decoration: none;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 34px;
    color: #FFFFFF;
  }
}

.hidden{
  display: none;
}

.mob__nav-close span{
  font-size: 70px;
  color: #FFFFFF;
}

.language_selector-mob{
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 700;
  background: transparent;
  color: white;
  border: 0;
  cursor: pointer;
  position: relative;

  &::after{
    content: '';
    position: absolute;
    left: 100%;
    top: 38%;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 10px solid #e8e8e8;
    clear: both;
    transition: 0.5s;
  }

}

.mob__language-cont{
  margin-right: 7%;
  margin-top: 15px;
  position: relative;
}

.mobmenu__main{

  list-style: none;
  margin: 0;
  padding:0;

  & li{
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;


    & a{
      text-decoration: none;
      color: white;
      margin: 10px 0;
      display: block;
      font-weight: 100;
    }

    & select{
      background-color: transparent;
      border: 0;
      color: white;

      & option{
        background-color: 07133A;
      }
    }

  }

}

.mobmenu__sec{
  list-style:none;
  position: relative;

  & a{
    line-height: 20px;
    font-weight: 400;
  }

  & li::before {
    content: "\2022";
    color: white;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    position: absolute;
  }
}

.mob__nav-menu{
  margin-left: 5%;
}

.header__contacts {
  padding-left: 5%;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  flex: 4;
  height: 100%;
}

.header__contacts-mail {

  & a{
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: white;
    text-decoration: none;
    font-style: normal;
  }

}

.header__contacts-phones {
  display: flex;
  flex-direction: column;

  & a{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: white;
    text-decoration: none;
    font-style: normal;
    margin-top: 10px;
  }

}

.header__worktime {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: white;
  flex:7;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.worktime__cont{
  display: flex;
  flex-direction: column;

  & span{
    margin-top: 5px;
  }
}

.language_selector{

  background: transparent;
    color: white;
    border: 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-decoration: none;
    font-style: normal;
    position: relative;
  cursor: pointer;

  &::after{
  content: '';
  position: absolute;
  left: 100%;
  top: 38%;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 10px solid #e8e8e8;
  clear: both;
  transition: 0.5s;
  }

}

 .lgactive button::after{
  transform: rotate(-180deg);
}


.languages{
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
  position: absolute;
  top: 88px;

  & li{
    text-align: center;
    margin-top: 5px;
  }

  & a {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: white;
    text-decoration: none;
    font-style: normal;
  }

}

.header__language {
  flex: 2;
  height: 100%;
}

.header__language-cont{
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;

}

//.header__language-ul {
//  background: transparent;
//  color: white;
//  border: 0;
//
//  & option{
//    font-family: 'Montserrat', sans-serif;
//    font-weight: 400;
//    font-size: 16px;
//    line-height: 19px;
//    color: white;
//    text-decoration: none;
//    font-style: normal;
//  }
//
//
//  & option:checked{
//    font-family: 'Montserrat', sans-serif;
//    font-weight: 700;
//    font-size: 20px;
//    line-height: 24px;
//    color: white;
//    text-decoration: none;
//    font-style: normal;
//  }
//}
//
//.header__language-item {
//  background: black;
//  color: white;
//  border: none;
//}

.wrapper {
  display: flex;
  flex-direction: row;
  min-height: 750px;
}

.main__block {
  flex: 3;
}

.main__menu{
  position: relative;
  width: 340px;
  height: auto;

  @include phones{
    display: none;
  }

}

.main__menu-acc{
  display: none;

  &-view{
    display: block;
  }
}

.main__menu-item-acc a, span{
  position: relative;
}

.main__menu-item-acc .acc___trigger::after{
  content: '';
  position: absolute;
  left: 108%;
  top: 37%;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid rgba(255,255,255,.5);
  clear: both;
  transition: 0.25s;
}

.sip-link::after{
  content: '';
  position: absolute;
  left: 108%;
  top: 37%;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid rgba(255,255,255,.5);
  clear: both;

}


.active span::after{
  transform: rotate(-180deg);
}

.main__menu-cont{
  position: fixed;
  height: 100%;
  background: linear-gradient(168.53deg, #02183D -12.84%, #1F0331 240.2%);
  width: 340px;
  z-index: 1;
  padding: 0 0 0 45px;
}

.non_fixed{
  position: absolute;
  height: 100vh;
  bottom: 0;
}



.main__menu-logo {

  width: 250px;
  margin: 40px 0;

  & img{
    width: 100%;
    height: auto;
  }

}

.main__menu-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.main__menu-item {
  margin: 0;
  padding: 0;
  margin-top: 10px;

  cursor: pointer;

  & a, span{
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: rgba(255,255,255,.5);;
    text-decoration: none;

  }

}



.main__menu-subitem{

  margin-top: 8px;

  & a{
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: rgba(255,255,255,.5);
    text-decoration: none;
  }
}

.active-link{

  & > a{
    font-weight: 700 !important;
    color: white;
  }

}

.content {
  padding: 70px;

  @include phones{
    padding: 20px 0;
  }

  & li{
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #333333;
    text-align: justify;
    margin-top: 20px;

    @include phones{
      font-size: 14px;
    }
  }

  & .little__height{
  line-height: 16px;
  }
}

.footer {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  flex-flow: nowrap;
  height: 450px;
  background: #010B1D;
  padding: 90px 4% 0 4%;

  @include phones{
    flex-flow: wrap;
    height: 800px;
    padding: 90px 3% 0 3%;

  }
}

.footer__logo {
  flex: 1;
  margin-right: 3%;

  @include phones{
    width: 50%;
  }
}

.footer__logo-img {

  width: 190px;
  margin-bottom: 20px;

  @include phones{
    width: 150px;
    margin-bottom: 37px;
  }

  & img{
    width: 100%;
    height: auto;
    margin-left: -10px;
  }

}

.footer__logo-workhours {
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.5);

  @include phones{
    font-size: 14px;
  }
}

.footer__logo-phones {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;

  & a{
    color: white;
    text-decoration: none;
    margin-top: 15px;
  }
  @include phones{
    font-size: 14px;
  }
}

.footer__mail {
  flex:2;

  @include phones{
    width: 50%;
    flex:1;
    font-size: 14px;
  }
}

.footer__mail-cont {

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.5);


  @include phones{
    font-size: 14px;
  }

  & a{
    text-decoration: none;
    font-weight: 500;
    color: white;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.footer__menu {
  flex: 1;

  @include phones{
    width: 50%;
    font-size: 14px;
    flex: 0;
    flex-basis: 150px;
  }
}

.footer__menu-item{
  margin-top: 10px;
}

.footer__menu-item,  .footer__menu-item a{
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-decoration: none;

  color: rgba(255, 255, 255, 0.5);

}

.footer__sip {
  flex: 1;

  @include phones{
    width: 50%;
    font-size: 14px;
    flex: 0;
    flex-basis: 150px;
    padding-left: 25px;

  }
}

.footer__menu-subitem{
  margin-top: 10px;
  margin-left: 30px;
}

.footer__menu-subitem, .footer__menu-subitem a {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-decoration: none;

  color: rgba(255, 255, 255, 0.5);

  @include phones{
    font-size: 14px;
  }
}

.footer__services {

  flex: 1;

  @include phones{
    width: 50%;
    flex: 0;
    flex-basis: 150px;
  }
}

.photo__content {

  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: flex-start;

  @include phones{
    flex-direction: column;
    align-items: center;
    padding: 0 15px;
  }
}
.photo__block {

  width: 350px;

  @include phones{
    width: 230px;
    margin: 10px 0;
  }

  & img{
    width: 100%;
    height: auto;
  }

  &-left{
    padding: 0 50px 50px 0;

    @include phones{
      padding: 0;
    }

  }

  &-right{
    padding: 0 0 50px 50px;

    @include phones{
      padding: 0;
    }
  }

  &-contacts{
    flex: 2;

    @include phones{
      margin-top: 40px;
    }
  }

  &-verification{
    padding: 0 0 0 50px;
    width: 270px;

    @include phones{
      display: none;
    }
  }
}
.text__block {
  flex: 1;

  &-contacts{
    flex: 2;
  }
}
.main__h1 {
  font-family: Oswald, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 53px;
  text-align: left;
  color: #333333;
  margin: 0 0 20px 0;;

  @include phones{
    font-size: 24px;
    padding: 0 15px
  }
}
.main__text {

  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  text-align: justify;
  margin-top: 20px;

  @include phones{
    font-size: 14px;
  }

  & a{
    color: #127B94;
    font-weight: bolder;
    text-decoration: none;
  }


}
.contact__button, .tabs__button {

  font-family: Montserrat, sans-serif;;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: white;
  text-decoration: none;
  cursor: pointer;
  background: linear-gradient(112.96deg, #08A095 -44.68%, #271D90 264.34%);
  box-shadow: 2px 4px 25px rgba(8, 160, 149, 0.3);
  border-radius: 100px;
  padding: 16px 60px;
  margin: 25px 0 70px;
  display: inline-block;
  max-width: 318px;
  text-align: center;

  @include phones{
    font-size: 22px;
    display: block;
    margin: 25px auto;
    width: 80%;
  }
}

.text__content {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  &-nomargin{
    margin-bottom: 0;
  }

  @include phones{
    padding: 0 15px;
  }
}
.compare__block{

  width: 330px;
  //height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 45px 50px;
  border: 4px solid #434393;
  margin-left: 8%;

  @include phones{
    margin-left: 0;
    margin-top: 20px;
  }

  & a{
    display: flex;
    flex-direction: column;
    text-decoration: none;
    font-family: Oswald, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
  }

  .arrow{
    font-size: 90px;
  }

  &-small{
    margin: 0;
    width:auto;
    padding: 20px 45px 20px 0;
    border: none;
    justify-content: center;

    @include phones{
      padding: 20px 20px 20px;
    }

    & a{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .arrow{

      font-size: 30px;
      margin-left: 10px;
    }

  }

}







.numbers__tabs {

  display: flex;
  flex-direction: column;
  margin-top: 30px;

  @include phones{
    padding: 0 15px;
  }
}
.numbers__tab-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @include phones{
    flex-flow: row wrap;
  }
}
.tab__list-item {

  margin: 0;
  padding: 0 0 12px;
  border-bottom: 2px solid #333;
  width: 100%;
  text-align: center !important;

  @include phones
  {
    width: 45%;
    margin-bottom: 15px;
  }
}
.tab__list-item-active {
  border-bottom: 10px solid #146E93;
}
.tab__list-link {
  text-decoration: none;
  font-family: Oswald, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 53px;
  color: #333333;
  cursor: pointer;
}
.numbers__tab-content {

  margin: 0;
  padding: 0;
  list-style: none;

  & span{
    line-height: 24px;
    display: block;
    min-height: 80px;
  }


}

.verification__tab-content{
  min-height: 300px;

  & li{
    padding: 10px 0 0 0;
  }
}

.tab__content-item {

  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 40px 0;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  color: #333333;

  & a{
    margin-top: 35px;

    @include phones{
      display: block;
      margin: 35px auto;
    }
  }
}
.tab__content-item{

  display: none;

}

.tab__content-item-active{

  display: block;

}
.tabs__button {

}

.price__table {
  //margin-top: 30px;
  margin-bottom: 60px;

  @include phones{
    .text__block{
      padding: 0 15px;
    }
  }
}
.sip__price-table {
  border-collapse:collapse;
  width: 100%;

  & th{
    height: 70px;
    background: linear-gradient(168.53deg, #02183D -12.84%, #1F0331 240.2%);
    color: white;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;

    @include phones{
      font-size: 11px;
    }
  }

  & td{
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    padding: 20px 2%;

    color: #000000;

    @include phones{
      font-size: 14px;
    }
  }

  & tr{
    border-bottom: 1px solid #33333340;
  }



}
.abon__col {

}
.min__col {

}
.underlim__col {

}

.contact__photo{
  height: 250px;
  width: 100%;
  background: url("../img/design2022/contact_block.jpg");
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: 100%;

  @include phones{
    background-size: cover;
  }

  & a{

  }
}

.contact__butt{
  padding: 50px 0;
}

.contact__h2{
  font-family: Oswald, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 53px;
  margin-top: 60px;
  margin-bottom: 15px;

  @include phones{
    font-size: 24px;
    padding: 0 18%;
    text-align: center;

  }
}

.menu__acc-ul{
  list-style: none;
}




.blog__index {

  //margin-top: 50px;

  & h1{
    margin-bottom: 20px;
  }

  @include phones{
    padding: 0 2%;
    margin-top: 20px;


  }
}
.blog__articles-list {

  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-flow: row wrap;

  @include phones{

    flex-direction: column;
  }

}
.blog__article-item {
  width: 45%;
  padding: 25px;
  margin: 0;
  background: #FFFFFF;
  box-shadow: 1px 4px 30px rgba(8, 160, 149, 0.1);
  display: flex;
  flex-direction: column;
  margin-right: 5%;

  @include phones{
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.blog__article-link {

  font-family: Oswald, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  text-decoration: none;
  color: #333333;
  flex: 3;
  margin-top: 15px;
}
.blog__articles-link {

}
.blog__article-cat {

  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #333333;
  opacity: 0.8;
  text-decoration: none;
  flex: 1;
}
.blog__articles-category {

}
.blog__article-date {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #333333;
  opacity: 0.8;
  flex: 1;
  margin-top: 25px;
}

.blog__breadcrumbs{
  font-family: Montserrat,sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #333;
  opacity: .8;
  text-decoration: none;
  margin-bottom: 20px;
  margin-left: 1%;

  & a{
    text-decoration: none;
  }
}

.blog__title{

  font-family: Oswald, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 53px;
  color: #333333;

  @include phones{
    font-size: 24px;
    line-height: 32px;
  }
}

.blog__article-text{
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #333333;

  & a{
    text-decoration: none;
    color: #127B94;
    font-weight: 600;
  }

  & p{
    text-align: justify;
  }

  @include phones{
    padding: 0 2%;
    font-size: 14px;
  }
}

.blog__h2{

  font-family: Oswald, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #333333;
}

.blog__img{

  display: block;
  width: 75%;
  margin: 35px auto;

  & img{
    width: 100%!important;
    height: auto;
    display: block;
    margin: 0 auto;
  }
}

.photo{

  text-align: center;

  & img{
    width: 100%;
    height: auto;
  }

  @include phones{
    padding: 0 10px;
  }

}

.callcentre__features {

  display: flex;
  flex-direction: row;
  margin-bottom: 40px;

  @include phones{
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }

  &-pbx{
    margin-top: 40px;

    & .callcentre__features-img{
      margin-top: 60px;
    }
  }



}
.callcentre__features-img {

  flex: 2;
  margin-right: -85px;

  @include phones{
    margin-right: 0;
    flex: 0;
    flex-basis: 340px;
  }

  & img{
    width: 100%;
    height: auto;
  }


}
.callcentre__features-list {
  background: #FFFFFF;
  box-shadow: 1px 4px 30px rgba(8, 160, 149, 0.1);
  padding: 30px 35px;
  flex: 1;

  & ul{
    padding: 0;
    margin: 0;
    list-style: none;
  }

  & li{
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    margin-top: 20px;
    border-left: 4px solid rgba(2, 24, 61, 1);
    padding-left: 25px;

    @include phones{
      font-size: 14px;
    }
  }

  & a{
    color: #127b94;
    font-weight: bolder;
    text-decoration: none;
  }
}
.callcentre__features-title {

  font-family: Oswald, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  padding: 0;
  margin: 0 0 25px;
  color: #333333;
}

.main__h2{
  font-family: Oswald, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 30px;
  color: #333333;
  margin-bottom: 30px;

  @include phones{
    font-size: 24px;
    text-align: center;
  }
}

.main__h3{
  font-family: Oswald, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 30px;
  color: #333333;
  margin-bottom: 30px;

  @include phones{
    font-size: 22px;
    text-align: center;
  }
}

.callcentre__func-cont{
  display: flex;
  flex-direction: row;

  @include phones{
    flex-direction: column;
  }
}

.callcentre__func-list{
  margin: 0;
  padding: 0 55px 0 0;
  list-style: none;
}

.callcentre__func-item{
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  margin-top: 20px;
  border-left: 4px solid rgba(2, 24, 61, 1);
  padding-left: 25px;

  @include phones{
    font-size: 14px;
  }

  & a{
    color: #127b94;
    font-weight: bolder;
    text-decoration: none;
  }
}

.callcentre_price-box{
  width: 150px;
}

.end__price_row{
  height: 70px;
  background: linear-gradient(168.53deg, #02183D -12.84%, #1F0331 240.2%);
    font-family: Montserrat, sans-serif;


  & td{
    color: white !important;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }
}

.sip__price-table-webcall td:first-child{
  text-align: left;
}

.audio__features{
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;

  @include phones{
    flex-direction: column;
  }
}

.audio__features-list{
  background: #FFFFFF;
  box-shadow: 1px 4px 30px rgba(8, 160, 149, 0.1);
  padding: 30px 35px;
  flex: 3;

  & ul{
    padding: 0;
    margin: 0;
    list-style: none;
  }

  & li{
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    margin-top: 20px;
    border-left: 4px solid rgba(2, 24, 61, 1);
    padding-left: 25px;

    @include phones{
      font-size: 14px;
    }

  }
}

.audio__block{
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include phones{
    padding-top: 35px;
  }
  & p{
    margin: 0 15% 10px;
  }
}

.audio_css{
  margin-top: 15px;
}

.sip__price-table-ippbx{
  width: 100%;
  table-layout: fixed;
  margin-top: 60px;

  & td{
    width: 100%;
  }
}

.cases__cont{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 25px 0;

  @include phones{
    flex-direction: column;
    padding: 0 2%;

    & h1{
      margin-bottom: 20px;
    }
  }
}

.cases__text-cont{
  flex: 3;
  padding-right: 7%;
}

.cases__img-cont{

  //width: 100px;
  flex: 1;

  & img{
    width: 100%;
    height: auto;
  }

}

.text__block-contacts h1{
  margin-bottom: 55px;

  @include phones{
    margin-bottom: 20px;
  }
}

.lazy-img{
  filter: blur(20px);
}

.show{
  display: block;
}

.main__ul{


}
.main__li{
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;

  @include phones{
    font-size: 14px;
  }

}

.verification__block{
  width: 270px;
}

.international__cont{
  display: none !important;
}

.streamint__cont{
  display: none;
}

.unitalkint__cont{
  display: none;
}

.lexicoint__cont{
  display: none;
}
