@mixin box-shadow($params){
	box-shadow: $params;
	-webkit-box-shadow: $params;
	-moz-box-shadow: $params;
}

@mixin border-radius($params){
	border-radius: $params;
	-webkit-border-radius: $params;
	-moz-border-radius: $params;
}

@mixin transition($params){	
	transition: $params;
	-webkit-transition: $params;
	-o-transition: $params;
	-moz-transition: $params;
}

@mixin box-sizing($params){
	-webkit-box-sizing: $params;
	-moz-box-sizing: $params;
	box-sizing: $params;
}


%clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

@mixin inlineblock($valign:top){
	display: inline-block;
	vertical-align: $valign;
}

//responsive
@function rem($px) {
	@return $px / 16px + 0rem;
}


@mixin desktop {
	@media screen and (max-width: 1200px) {
		@content;
	}
}

@mixin laptop {
	@media screen and (max-width: 992px) {
		@content;
	}
}


@mixin tablets {
	@media screen and (max-width: 768px) {
		@content;
	}
}


@mixin phones {
	@media screen and (max-width: 480px) {
		@content;
	}
}